import React, { Fragment, useEffect } from "react"
import { graphql } from "gatsby"
import { injectIntl, navigate } from "gatsby-plugin-intl"
import styled from "@emotion/styled"

import Testimonials from "../../../components/Layout/Testimonials"
import Footer from "../../../components/Layout/Footer"
import SeoComp from "../../../components/SeoComp"
import OfferProductSection from "../../../components/OfferProductSection"
import OfferBottomSection from "../../../components/OfferBottomSection"
import BlackFridayOfferProductSection from "../../../components/BlackFridayOfferProductSection"
import HomeChristmasSection from "../../../components/Layout/HomeChristmasSection"
import { pageViewEventScript } from "../../../utils/additionalScriptHelpers"

const Offres = ({ intl, data }) => {
  useEffect(() => {
    if (intl.locale != "fr") navigate(`/${intl.locale}/shop/`)
  }, [intl.locale])
  useEffect(() => {
    data.allDirectusSeoTranslation.nodes[0] &&
      data.allDirectusSeoTranslation.nodes[0].title &&
      pageViewEventScript(
        data.allDirectusSeoTranslation.nodes[0].title,
        intl.locale
      )
  }, [])
  return (
    data &&
    process.env &&
    (process.env.GATSBY_ACTIVE_ENV === "eur" ||
      process.env.GATSBY_ACTIVE_ENV === "general") &&
    intl.locale === "fr" &&
    data.allDirectusOfferPageTranslation.nodes.length > 0 && (
      <Fragment>
        <SeoComp
          description={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].description
          }
          title={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].title
          }
          twitter_card={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].twitter_card
          }
          image={
            data.allDirectusSeoTranslation.nodes[0] &&
            data.allDirectusSeoTranslation.nodes[0].image &&
            data.allDirectusSeoTranslation.nodes[0].image.data &&
            data.allDirectusSeoTranslation.nodes[0].image.data.url
          }
          hreflangPathname={{
            fr: "shop/offres",
            en: "",
            de: "",
            es: "",
          }}
        />

        {data.allDirectusOfferPageTranslation.nodes[0].product_section && (
          <OfferProductSection
            data={data.allDirectusOfferPageTranslation.nodes[0].product_section}
            price={data.allShopifyProduct.edges}
          />
        )}

        {/* {data.allDirectusOfferPageTranslation.nodes[0]
          .sommier_product_section && (
          <OfferProductSection
            data={
              data.allDirectusOfferPageTranslation.nodes[0]
                .sommier_product_section
            }
            price={data.allShopifyProduct.edges}
          />
        )} */}

        {/* {data.allDirectusOfferPageTranslation.nodes[0]
          .black_friday_product_section && (
          <BlackFridayOfferProductSection
            data={
              data.allDirectusOfferPageTranslation.nodes[0]
                .black_friday_product_section
            }
            price={data.allShopifyProduct.edges}
          />
        )} */}

        {/* {data.allDirectusChristmasBannerTranslation.nodes[0]
          .christmas_section &&
          data.allDirectusGiftCardPageTranslation.nodes[0].gift_card_popup && (
            <HomeChristmasSection
              data={
                data.allDirectusChristmasBannerTranslation.nodes[0]
                  .christmas_section
              }
              popupData={
                data.allDirectusGiftCardPageTranslation.nodes[0].gift_card_popup
              }
              page="offer"
            />
          )} */}

        {data.allDirectusOfferPageTranslation.nodes[0].bottom_section && (
          <OfferBottomSection
            data={data.allDirectusOfferPageTranslation.nodes[0].bottom_section}
          />
        )}

        {data.allDirectusCommonTranslation.nodes.length > 0 && (
          <Testimonials
            data={data.allDirectusCommonTranslation.nodes[0].testimonials.list}
          />
        )}

        {data.allDirectusFooterTranslation.nodes.length > 0 && (
          <Footer data={data.allDirectusFooterTranslation.nodes[0]} />
        )}
      </Fragment>
    )
  )
}

export const query = graphql`
  query($lang: String!) {
    site {
      siteMetadata {
        title
      }
    }
    allDirectusOfferPageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        product_section {
          section_title
          list {
            image
            alt
            name
            title
            text
            top_text
            discount
            button_text
            button_url
          }
        }
        sommier_product_section {
          section_title
          list {
            image
            alt
            name
            title
            text
            top_text
            discount
            button_text
            button_url
          }
        }
        black_friday_product_section {
          section_title
          list {
            image
            alt
            name
            title
            text
            top_text
            discount
            button_text
            button_url
          }
        }
        bottom_section {
          image
          alt
          title
          text
          button_text
          button_url
        }
      }
    }
    allDirectusChristmasBannerTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        christmas_section {
          image
          alt
          title
          text
        }
      }
    }
    allDirectusGiftCardPageTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        gift_card_popup {
          title
          text
          list {
            image
            image_alt
            text
          }
        }
      }
    }
    allDirectusCommonTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        testimonials {
          list {
            image
            text
          }
        }
      }
    }
    allDirectusFooterTranslation(filter: { language: { eq: $lang } }) {
      nodes {
        facebook
        gbp_facebook
        youtube
        twitter
        instagram
        linkedin
        about_us {
          name
          list {
            link
            list
          }
        }
        contact {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        purchases {
          list {
            list
            link
          }
          name
        }
        help {
          list {
            link
            list
          }
          name
        }
        about_us_menu {
          name
          list {
            link
            list
          }
        }
        knowledge_center_menu {
          name
          list {
            link
            list
          }
        }
        shop_menu {
          name
          list {
            link
            list
          }
        }
        support_menu {
          name
          list {
            link
            list
          }
          button {
            link
            list
          }
        }
        newsletter_heading
        follow_us_heading
        footer_bottom {
          list {
            link
            list
          }
        }
      }
    }
    allShopifyProduct(sort: { fields: handle }) {
      edges {
        node {
          handle
          priceRangeV2 {
            minVariantPrice {
              amount
            }
            maxVariantPrice {
              amount
            }
          }
        }
      }
    }
    allDirectusSeoTranslation(
      filter: { language: { eq: $lang }, seo: { page_name: { eq: "offres" } } }
    ) {
      nodes {
        description
        title
        twitter_card
        twitter_title
        url
        image {
          data {
            url
          }
        }
      }
    }
  }
`

export default injectIntl(Offres)
